
import { defineComponent, nextTick, onMounted, ref, watch } from 'vue';
import useWallet from '@/composables/wallet';
import useCluster from '@/composables/cluster';
import { initGemFarm } from '@/common/gem-farm';
import { PublicKey } from '@solana/web3.js';
import ConfigPane from '@/components/ConfigPane.vue';
import FarmerDisplay from '@/components/proleague-farm/FarmerDisplay.vue';
import Vault from '@/components/proleague-bank/Vault.vue';
import { INFT } from '@/common/web3/NFTget';
import { findFarmerPDA, stringifyPKsAndBNs } from '@gemworks/gem-farm-ts';
import { useToast, POSITION, TYPE } from 'vue-toastification';

export default defineComponent({
  components: { Vault, FarmerDisplay, ConfigPane },
  setup(props, ctx) {
    const { wallet, getWallet } = useWallet();
    const { cluster, getConnection } = useCluster();
    const vaultComponent = ref<null | { endUnstakeAll: () => null }>(null);
    const toast = useToast();

    let gf: any;
    watch([wallet, cluster], async () => {
      await freshStart();
    });

    //needed in case we switch in from another window
    onMounted(async () => {
      await freshStart();
    });

    // --------------------------------------- farmer details
    const farm = ref<string>();
    const farmAcc = ref<any>();

    const farmerIdentity = ref<string>();
    const farmerAcc = ref<any>();
    const farmerState = ref<string>();

    const availableA = ref<string>();
    const availableB = ref<string>();

    const pendingFullEndStaking = ref<boolean>(false);

    const farmAccSearchDone = ref<boolean>(false);

    farm.value = process.env.VUE_APP_PROLEAGUE_FARM_ID;

    // --------------------------------------- adding extra gem
    const selectedWalletNFTs = ref<INFT[]>([]);
    const currentWalletNFTs = ref<INFT[]>([]);
    const selectedVaultNFTs = ref<INFT[]>([]);
    const currentVaultNFTs = ref<INFT[]>([]);

    //auto loading for when farm changes
    watch(farm, async () => {
      await freshStart();
    });

    watch([pendingFullEndStaking, farmerState], async () => {
      if (farmerState.value == 'pendingCooldown') {
        await endStaking();
      } else {
        pendingFullEndStaking.value = false;
      }
    });

    const updateAvailableRewards = async () => {
      availableA.value = farmerAcc.value.rewardA.accruedReward
        .sub(farmerAcc.value.rewardA.paidOutReward)
        .toString();
      availableB.value = farmerAcc.value.rewardB.accruedReward
        .sub(farmerAcc.value.rewardB.paidOutReward)
        .toString();
    };

    const fetchFarm = async () => {
      farmAcc.value = await gf.fetchFarmAcc(new PublicKey(farm.value!));
      console.log(
        `farm found at ${farm.value}:`,
        stringifyPKsAndBNs(farmAcc.value)
      );
    };

    const fetchFarmer = async () => {
      const [farmerPDA] = await findFarmerPDA(
        new PublicKey(farm.value!),
        getWallet()!.publicKey!
      );
      farmerIdentity.value = getWallet()!.publicKey?.toBase58();
      farmerAcc.value = await gf.fetchFarmerAcc(farmerPDA);
      farmAccSearchDone.value = true;
      farmerState.value = gf.parseFarmerState(farmerAcc.value);
      await updateAvailableRewards();
      console.log(
        `farmer found at ${farmerIdentity.value}:`,
        stringifyPKsAndBNs(farmerAcc.value)
      );
    };

    const freshStart = async () => {
      if (getWallet() && getConnection()) {
        gf = await initGemFarm(getConnection(), getWallet()!);
        farmerIdentity.value = getWallet()!.publicKey?.toBase58();

        //reset stuff
        farmAcc.value = undefined;
        farmerAcc.value = undefined;
        farmerState.value = undefined;
        availableA.value = undefined;
        availableB.value = undefined;

        try {
          await fetchFarm();
          await fetchFarmer();
        } catch (e) {
          console.log(`farm with PK ${farm.value} not found :(`);
        }
      }
    };

    const initFarmer = async () => {
      await gf.initFarmerWallet(new PublicKey(farm.value!));
      await fetchFarmer();
    };

    // --------------------------------------- staking
    const beginStaking = () => {
      return new Promise(async (resolve, reject) => {
        await gf.stakeWallet(new PublicKey(farm.value!)).catch((error: any) => {
          reject(error);
        });

        selectedWalletNFTs.value = [];
        resolve(true);
      });
    };

    const endStaking = () => {
      return new Promise(async (resolve, reject) => {
        await gf
          .unstakeWallet(new PublicKey(farm.value!))
          .catch((error: any) => {
            reject(error);
          });
        selectedWalletNFTs.value = [];
        resolve(true);
      });
    };

    const claim = async () => {
      await gf.claimWallet(
        new PublicKey(farm.value!),
        new PublicKey(farmAcc.value.rewardA.rewardMint!),
        new PublicKey(farmAcc.value.rewardB.rewardMint!)
      );
      await fetchFarmer();
    };

    const refreshFarmer = async () => {
      await gf.refreshFarmerWallet(
        new PublicKey(farm.value!),
        new PublicKey(farmerIdentity.value!)
      );
      await fetchFarmer();
    };

    const refreshAll = async () => {
      await fetchFarmer();
    };

    const handleSelectedWalletNFT = (walletSelectedNFTs: INFT[]) => {
      selectedWalletNFTs.value = walletSelectedNFTs;
    };

    const handleCurrentWalletNFT = (walletCurrentNFTs: INFT[]) => {
      currentWalletNFTs.value = walletCurrentNFTs;
    };

    const handleSelectedVaultNFT = (vaultSelectedNFTs: INFT[]) => {
      selectedVaultNFTs.value = vaultSelectedNFTs;
    };

    const handleCurrentVaultNFT = (vaultCurrentNFTs: INFT[]) => {
      currentVaultNFTs.value = vaultCurrentNFTs;
    };

    const handleVaultBeginStaking = () => {
      const notificationId = toast(
        'Waiting for transaction to begin staking ',
        {
          type: TYPE.INFO,
          position: POSITION.BOTTOM_LEFT,
        }
      );
      setTimeout(async () => {
        await beginStaking().catch((error: any) => {
          toast('Transaction to begin staking failed. Please refresh and try again.', {
            type: TYPE.ERROR,
            position: POSITION.BOTTOM_LEFT,
            timeout: 1000,
          });
        });
        fetchFarmer();
        toast.dismiss(notificationId);
      }, 0);
    };

    const fullEndStaking = () => {
      setTimeout(async () => {
        let notificationId = toast('Waiting for transaction to end staking', {
          type: TYPE.INFO,
          position: POSITION.BOTTOM_LEFT,
        });
        //End Stake
        await endStaking().catch((error: any) => {
          toast('Transaction to end staking failed. Please refresh and try again.', {
            type: TYPE.ERROR,
            position: POSITION.BOTTOM_LEFT,
            timeout: 1000,
          });
        });
        toast.dismiss(notificationId);
        notificationId = toast('Waiting for transaction to end cooldown.', {
          type: TYPE.INFO,
          position: POSITION.BOTTOM_LEFT,
        });
        //Ending Cooldown
        await endStaking().catch((error: any) => {
          toast('Transaction to end cooldown failed. Please refresh and try again.', {
            type: TYPE.ERROR,
            position: POSITION.BOTTOM_LEFT,
            timeout: 1000,
          });
        });

        fetchFarmer();
        toast.dismiss(notificationId);

        pendingFullEndStaking.value = false;
      }, 0);
    };

    const addSingleGem = async (
      gemMint: PublicKey,
      gemSource: PublicKey,
      creator: PublicKey
    ) => {
      await gf.flashDepositWallet(
        new PublicKey(farm.value!),
        '1',
        gemMint,
        gemSource,
        creator
      );
      await fetchFarmer();
    };

    const addGems = async () => {
      let notificationId: any;
      let notificationIds: any[] = [];
      await Promise.all(
        selectedWalletNFTs.value.map((nft, index) => {
          notificationId = toast(
            'Waiting for transaction ' +
              (index + 1) +
              '/' +
              selectedWalletNFTs.value.length,
            { type: TYPE.ERROR, position: POSITION.BOTTOM_LEFT }
          );
          notificationIds.push(notificationId);

          const creator = new PublicKey(
            //todo currently simply taking the 1st creator
            (nft.onchainMetadata as any).data.creators[0].address
          );

          addSingleGem(nft.mint, nft.pubkey!, creator);
        })
      );

      notificationIds.forEach((notId: any) => {
        toast.dismiss(notId);
      });
    };

    return {
      wallet,
      farm,
      farmAcc,
      farmer: farmerIdentity,
      farmerAcc,
      farmerState,
      availableA,
      availableB,
      initFarmer,
      beginStaking,
      endStaking,
      claim,
      refreshFarmer,
      selectedWalletNFTs,
      currentWalletNFTs,
      selectedVaultNFTs,
      currentVaultNFTs,
      handleSelectedWalletNFT,
      handleCurrentWalletNFT,
      handleSelectedVaultNFT,
      handleCurrentVaultNFT,
      handleVaultBeginStaking,
      fullEndStaking,
      addGems,
      vaultComponent,
      refreshAll,
      fetchFarmer,
    };
  },
});
