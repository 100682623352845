import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import Toast, { PluginOptions } from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import './index.css';

const options: PluginOptions = {
    // You can set your default options here
};

createApp(App).use(router).use(Toast, options).mount('#app');
