<template>
  <header class="flex place-content-between items-center p-8">
		<div class="logo mr-8">
			<a href="#"><img src="images/logo.svg" alt=""></a>
		</div>
		<div class="borderHeader"></div>
		<div class="alpha flex items-center ml-8">
			<a href="#"><img src="images/alpha.svg" alt=""></a>
			<img class="ml-8" src="images/right-arrow.svg" alt="">
		</div>
	</header>
</template>

<script>
export default {};
</script>

<style scoped>
</style>
