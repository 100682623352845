<template>
  <footer class="flex place-content-between items-center p-8">
		<div class="logo mr-8">
			<a href="#"><img src="images/logo.svg" alt=""></a>
		</div>
		<div class="borderHeader"></div>
		<div class="copyright flex items-center ml-8">© 2022 Proleague.gg</div>
	</footer>
</template>

<script>
export default {};
</script>

<style scoped>
.footer {
  @apply w-full flex justify-center align-middle bg-gray-200 text-center;
  position: absolute;
  bottom: 0px;
  height: 40px;
}
</style>
