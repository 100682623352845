<template>
  <div>
    <TheHeader />
    
    <main class="p-8 flex items-center">
      <router-view />
    </main>

    <div class="pt-10"></div>
    <TheFooter />
  </div>
</template>

<script>
import TheHeader from '@/components/proleague-farm/TheHeader';
import TheFooter from '@/components/proleague-farm/TheFooter';
export default {
  components: { TheFooter, TheHeader },
};
</script>

<style>
* {
  font-family: 'Press Start 2P', monospace;
}
input[type='radio']:checked + span {
  @apply text-black;
}
</style>
