import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-32997eaa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "itemImage" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "badge" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["item", { 'card-selected': _ctx.selected }]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSelect && _ctx.toggleSelect(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: _ctx.nft.externalMetadata.image,
        alt: _ctx.nft.onchainMetadata.data.name
      }, null, 8, _hoisted_2),
      _createElementVNode("span", _hoisted_3, "#" + _toDisplayString(_ctx.nft.externalMetadata.attributes[0].value), 1)
    ])
  ], 2))
}