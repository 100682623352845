
import { defineComponent, onMounted, ref, watch } from 'vue';
import NFTGrid from '@/components/proleague-bank/NFTGrid.vue';
import ArrowButton from '@/components/ArrowButton.vue';
import useWallet from '@/composables/wallet';
import useCluster from '@/composables/cluster';
import {
  getNFTMetadataForMany,
  getNFTsByOwner,
  INFT,
} from '@/common/web3/NFTget';
import { initGemBank } from '@/common/gem-bank';
import { initGemFarm } from '@/common/gem-farm';
import { PublicKey } from '@solana/web3.js';
import { getListDiffBasedOnMints, removeManyFromList } from '@/common/util';
import { BN } from '@project-serum/anchor';
import { useToast, POSITION, TYPE } from 'vue-toastification';

export default defineComponent({
  components: { ArrowButton, NFTGrid },
  props: {
    vault: String,
    farmerState: String,
  },
  emits: [
    'selected-wallet-nft',
    'current-wallet-nft',
    'selected-vault-nft',
    'current-vault-nft',
    'vault-begin-staking',
    'vault-full-end-staking',
    'vault-some-end-staking',
    'vault-staked-add-nft',
    'refresh-account',
  ],
  setup(props, ctx) {
    const toast = useToast();
    const { wallet, getWallet } = useWallet();
    const { cluster, getConnection } = useCluster();

    // --------------------------------------- state

    //current walet/vault state
    const currentWalletNFTs = ref<INFT[]>([]);
    const currentVaultNFTs = ref<INFT[]>([]);
    //selected but not yet moved over in FE
    const selectedWalletNFTs = ref<INFT[]>([]);
    const selectedVaultNFTs = ref<INFT[]>([]);
    //moved over in FE but not yet onchain
    const desiredWalletNFTs = ref<INFT[]>([]);
    const desiredVaultNFTs = ref<INFT[]>([]);
    //moved over onchain
    const toWalletNFTs = ref<INFT[]>([]);
    const toVaultNFTs = ref<INFT[]>([]);

    let stakeLabel = ref<String>('Stake Selected');
    let unstakeLabel = ref<String>('Unstake All');
    let whitelistCreatorAddresses: any[];

    const errorMessage = ref<String>('');
    const walletIsLoading = ref<boolean>(true);
    const vaultIsLoading = ref<boolean>(true);

    // --------------------------------------- populate initial nfts

    const populateWalletNFTs = async () => {
      // zero out to begin with
      currentWalletNFTs.value = [];
      selectedWalletNFTs.value = [];
      desiredWalletNFTs.value = [];

      if (getWallet()) {
        const ownedNfts = await getNFTsByOwner(
          getWallet()!.publicKey!,
          getConnection()
        );

        walletIsLoading.value = false;

        if (whitelistCreatorAddresses.length) {
          const currentWalletNFTsFiltered = ownedNfts.filter((nft) => {
            return whitelistCreatorAddresses.some((creator) => {
              if (
                Array.isArray((nft.onchainMetadata as any).data.creators) &&
                (nft.onchainMetadata as any).data.creators.length
              ) {
                return (
                  creator.account.whitelistedAddress.toBase58() ===
                  (nft.onchainMetadata as any).data.creators[0].address
                );
              }
              return false;
            });
          });

          currentWalletNFTs.value = [...currentWalletNFTsFiltered];
          desiredWalletNFTs.value = [...currentWalletNFTsFiltered];
        } else {
          currentWalletNFTs.value = [...ownedNfts];
          desiredWalletNFTs.value = [...currentWalletNFTs.value];
        }

        ctx.emit('current-wallet-nft', currentWalletNFTs.value);
      }
    };

    const populateVaultNFTs = async () => {
      // zero out to begin with
      currentVaultNFTs.value = [];
      selectedVaultNFTs.value = [];
      desiredVaultNFTs.value = [];

      const foundGDRs = await gb.fetchAllGdrPDAs(vault.value);
      if (foundGDRs && foundGDRs.length) {
        gdrs.value = foundGDRs;
        console.log(`found a total of ${foundGDRs.length} gdrs`);

        const mints = foundGDRs.map((gdr: any) => {
          return { mint: gdr.account.gemMint };
        });
        currentVaultNFTs.value = await getNFTMetadataForMany(
          mints,
          getConnection()
        );

        ctx.emit('current-vault-nft', currentVaultNFTs.value);

        desiredVaultNFTs.value = [...currentVaultNFTs.value];
        console.log(
          `populated a total of ${currentVaultNFTs.value.length} vault NFTs`
        );
      }

      vaultIsLoading.value = false;
    };

    const updateVaultState = async () => {
      vaultAcc.value = await gb.fetchVaultAcc(vault.value);
      bank.value = vaultAcc.value.bank;
      vaultLocked.value = vaultAcc.value.locked;
    };

    watch([wallet, cluster], async () => {
      gb = await initGemBank(getConnection(), getWallet()!);

      //populate wallet + vault nfts
      await Promise.all([populateWalletNFTs(), populateVaultNFTs()]);
    });

    onMounted(async () => {
      gb = await initGemBank(getConnection(), getWallet()!);
      gf = await initGemFarm(getConnection(), getWallet()!);

      //prep vault + bank variables
      vault.value = new PublicKey(props.vault!);
      await updateVaultState();
      farmerState.value = props.farmerState;

      console.log('Farmer Vault State', props.farmerState);

      vaultAcc.value = await gb.fetchVaultAcc(vault.value);
      bank.value = vaultAcc.value.bank;
      bankAcc.value = await gb.fetchBankAcc(bank.value);

      whitelistCreatorAddresses = await gf.fetchAllWhitelistProofPDAs(
        new PublicKey(vaultAcc.value.bank)
      );

      //populate wallet + vault nfts
      await Promise.all([populateWalletNFTs(), populateVaultNFTs()]);
    });

    // --------------------------------------- moving nfts

    const handleWalletSelected = (e: any) => {
      if (e.selected) {
        selectedWalletNFTs.value.push(e.nft);
      } else {
        const index = selectedWalletNFTs.value.indexOf(e.nft);
        selectedWalletNFTs.value.splice(index, 1);
      }

      if (!selectedWalletNFTs.value.length) {
        stakeLabel.value = 'Stake Selected';
      } else if (selectedWalletNFTs.value.length) {
        stakeLabel.value =
          'Stake ' + selectedWalletNFTs.value.length + ' Selected';
      }

      ctx.emit('selected-wallet-nft', selectedWalletNFTs.value);
    };

    const handleVaultSelected = (e: any) => {
      if (e.selected) {
        selectedVaultNFTs.value.push(e.nft);
      } else {
        const index = selectedVaultNFTs.value.indexOf(e.nft);
        selectedVaultNFTs.value.splice(index, 1);
      }

      if (!selectedVaultNFTs.value.length) {
        unstakeLabel.value = 'Unstake All';
      } else if (selectedVaultNFTs.value.length) {
        unstakeLabel.value =
          'Unstake ' + selectedVaultNFTs.value.length + ' Selected';
      }

      ctx.emit('selected-vault-nft', selectedVaultNFTs.value);
    };

    const moveNFTsFE = (moveLeft: boolean) => {
      if (moveLeft) {
        //push selected vault nfts into desired wallet
        desiredWalletNFTs.value.push(...selectedVaultNFTs.value);
        //remove selected vault nfts from desired vault
        removeManyFromList(selectedVaultNFTs.value, desiredVaultNFTs.value);
        //empty selection list
        selectedVaultNFTs.value = [];
      } else {
        //push selected wallet nfts into desired vault
        desiredVaultNFTs.value.push(...selectedWalletNFTs.value);
        //remove selected wallet nfts from desired wallet
        removeManyFromList(selectedWalletNFTs.value, desiredWalletNFTs.value);
        //empty selected walelt
        selectedWalletNFTs.value = [];
      }
    };

    const stakeAll = async () => {
      if (!selectedWalletNFTs.value.length) {
        toast('Please select NFTs to stake', {
          type: TYPE.INFO,
          position: POSITION.BOTTOM_LEFT,
        });
        return;
      }

      if (farmerState.value == 'staked') {
        ctx.emit('vault-staked-add-nft', true);
      } else {
        moveNFTsFE(false);
        setTimeout(async () => {
          await moveNFTsToVaultOnChain();
          ctx.emit('vault-begin-staking', true);
        }, 500);
      }
    };

    const beginStaking = async () => {
      ctx.emit('vault-begin-staking', true);
    };

    const startUnstakeAll = async () => {
      ctx.emit('vault-full-end-staking', true);
    };

    const endUnstakeAll = async () => {
      if (!selectedVaultNFTs.value.length) {
        for (const nft of currentVaultNFTs.value) {
          selectedVaultNFTs.value.push(nft);
        }
      }

      await moveNFTsFE(true);

      setTimeout(async () => {
        await moveNFTsToWalletOnChain();
        ctx.emit('refresh-account', true);
      }, 1000);
    };

    const moveNFTsToVaultOnChain = async () => {
      let notificationId: any;

      let vaultLength = toVaultNFTs.value.length;
      await Promise.all(
        toVaultNFTs.value.map(async (nft, index) => {
          notificationId = toast(
            'Waiting for transaction ' + (index + 1) + '/' + vaultLength,
            {
              type: TYPE.INFO,
              position: POSITION.BOTTOM_LEFT,
            }
          );

          const creator = new PublicKey(
            (nft.onchainMetadata as any).data.creators[0].address
          );
          await depositGem(nft.mint, creator, nft.pubkey!).catch((error) => {
            toast.dismiss(notificationId);
            toast(
              'Transaction ' +
                (index + 1) +
                '/' +
                vaultLength +
                ' failed, please try again',
              {
                type: TYPE.ERROR,
                position: POSITION.BOTTOM_LEFT,
                timeout: 1000,
              }
            );
          });
        })
      );

      await Promise.all([populateWalletNFTs(), populateVaultNFTs()]);
      ctx.emit('refresh-account', true);
    };

    const moveNFTsToWalletOnChain = async () => {
      let notificationId: any;

      let walletLength = toWalletNFTs.value.length;

      await Promise.all(
        toWalletNFTs.value.map(async (nft, index) => {
          notificationId = toast(
            'Waiting for transaction ' + (index + 1) + '/' + walletLength,
            {
              type: TYPE.INFO,
              position: POSITION.BOTTOM_LEFT,
            }
          );

          await withdrawGem(nft.mint).catch((error) => {
            toast.dismiss(notificationId);
            console.log(error);
            toast(
              'Transaction ' +
                (index + 1) +
                '/' +
                walletLength +
                ' failed, please try again',
              {
                type: TYPE.ERROR,
                position: POSITION.BOTTOM_LEFT,
                timeout: 1000,
              }
            );
          });
        })
      );

      await Promise.all([populateWalletNFTs(), populateVaultNFTs()]);
      ctx.emit('refresh-account', true);
    };

    //to vault = vault desired - vault current
    watch(
      desiredVaultNFTs,
      () => {
        toVaultNFTs.value = getListDiffBasedOnMints(
          desiredVaultNFTs.value,
          currentVaultNFTs.value
        );
      },
      { deep: true }
    );

    //to wallet = wallet desired - wallet current
    watch(
      desiredWalletNFTs,
      () => {
        toWalletNFTs.value = getListDiffBasedOnMints(
          desiredWalletNFTs.value,
          currentWalletNFTs.value
        );
      },
      { deep: true }
    );

    // --------------------------------------- gem bank

    let gb: any;
    let gf: any;
    const bank = ref<PublicKey>();
    const vault = ref<PublicKey>();
    const vaultAcc = ref<any>();
    const bankAcc = ref<any>();
    const gdrs = ref<PublicKey[]>([]);
    const vaultLocked = ref<boolean>(false);
    const farmerState = ref<any>();

    const depositGem = (
      mint: PublicKey,
      creator: PublicKey,
      source: PublicKey
    ) => {
      return new Promise(async (resolve, reject) => {
        await gb
          .depositGemWallet(
            bank.value,
            vault.value,
            new BN(1),
            mint,
            source,
            creator
          )
          .catch((e: any) => {
            reject(e);
          });
        console.log('deposit done...');
        resolve(true);
      });
    };

    const withdrawGem = (mint: PublicKey) => {
      return new Promise(async (resolve, reject) => {
        await gb
          .withdrawGemWallet(bank.value, vault.value, new BN(1), mint)
          .catch((e: any) => {
            reject(e);
          });
        console.log('withdrawal done...');
        resolve(true);
      });
    };

    // --------------------------------------- return

    return {
      wallet,
      desiredWalletNFTs,
      desiredVaultNFTs,
      currentVaultNFTs,
      toVaultNFTs,
      toWalletNFTs,
      handleWalletSelected,
      handleVaultSelected,
      moveNFTsFE,
      moveNFTsToVaultOnChain,
      moveNFTsToWalletOnChain,
      stakeAll,
      startUnstakeAll,
      endUnstakeAll,
      bank,
      // eslint-disable-next-line vue/no-dupe-keys
      vault,
      vaultLocked,
      stakeLabel,
      unstakeLabel,
      selectedVaultNFTs,
      errorMessage,
      beginStaking,
      toast,
      walletIsLoading,
      vaultIsLoading,
    };
  },
});
